import React from "react"
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { BlockRenderer, Grid, Margin } from '@components/atoms'
import { H1, H2, Tag } from '@components/atoms/BlockRenderer'
import { GridItem, Hero, Tags } from '@components/molecules'
import { RelatedPosts, TransitionMask } from '@components/organisms'
import { WithData, getCdnPath } from '@utils'
import { ProjectProps } from '@types'
import { palette } from '@theme'
import { RelatedProps } from '@components/organisms/RelatedPosts'


const CaseStudyTemplate = ({ 
  pageContext: {
    settings,
  },
  data: {
    sanityPost: { 
      title, 
      description,
      slug,
      shareImage,
      heroType, 
      heroHeight,
      heroImageConditional, 
      heroVideoConditional,
      gridItems,
      gridSnap,
      tags,
      _rawIntroduction,
      inverted,
    },
    sanityPage
  },
}: ProjectProps): React.ReactElement => {
  const projectSettings = {
    ...settings,
    subtitle: title,
    shareImage: shareImage || settings.shareImage,
    keywords: tags ? tags.map(tag => tag.tag).join(', ') : settings.keywords,
    description: description || settings.description,
  }
  
  const parsedTitle = 'TWA: ' + title
  const meta = [
    { property: "og:title", content: parsedTitle },
    { name: "twitter:title", content: parsedTitle },
    { name: "description", content: description },
    { name: "twitter:description", content: description },
    { property: "og:description", content: description },
    { property: "og:image", content:  getCdnPath(`${projectSettings.shareImage?.asset.url}?w=640&fm=jpg`) },
    { name: "twitter:image", content:  getCdnPath(`${projectSettings.shareImage?.asset.url}?w=640&fm=jpg`) },
  ]

  let previousPost: RelatedProps | undefined
  let nextPost: RelatedProps | undefined
  const filteredNodes = sanityPage.gridItems.filter(node => node.post && node.post.slug)
  filteredNodes.map((node: any, i: number) => {
    if( node.post.slug.current === slug.current ) {
      previousPost = (i === 0) ? filteredNodes[filteredNodes.length - 1].post : filteredNodes[i - 1].post
      nextPost = (i === filteredNodes.length - 1) ? filteredNodes[0].post : filteredNodes[i + 1].post
    }
  })

  return (
    <>
      <Helmet {...{meta}} title={parsedTitle} >
        <title>{parsedTitle}</title>
      </Helmet>
            
      <StyledHero {...{ heroType, heroImageConditional, heroVideoConditional, heroHeight }} />
        
      <StyledMargin>
        <Grid>
          <Title>
            <H1>{title}</H1>
          </Title>
          <Description>
            <H2>{description}</H2>
          </Description>
          <PageIntroduction>
            <BlockRenderer>{_rawIntroduction}</BlockRenderer>
            {tags && (
              <>
                <StyledTag>Scope</StyledTag>
                <Tags {...{ tags }} />
              </>
            )}
          </PageIntroduction>
        </Grid>
      </StyledMargin>

      <Margin>
        <Grid {...{ gridSnap }} >
          {gridItems.filter(p => !!p).map((item) => (
            <GridItem 
              key={item._key} 
              {...item} 
            />
          ))}
          <RelatedPosts {...{ previousPost, nextPost, }} />
        </Grid>
      </Margin>

      <TransitionMask {...{ inverted }} />
    </>
  )
}


const StyledHero = styled(props => <Hero {...props} />)`
  @media only screen and (max-width: 743px) {
    padding-top: 140px;
  }
`


const StyledMargin = styled(props => <Margin {...props} />)`
  padding: 50px 0px;
  @media only screen and (min-width: 744px) {
    padding: 100px 0px;
  }
`


const Title = styled.div`
  grid-column: 1 / span 6;
  @media only screen and (min-width: 744px) {
    grid-column: 3 / span 9;
  }
`

const Description = styled.div`
  grid-column: 1 / span 6;
  @media only screen and (min-width: 744px) {
    grid-column: 3 / span 4;
  }
`


const PageIntroduction = styled.header`
  grid-column: 1 / span 6;
  @media only screen and (min-width: 744px) {
    grid-column: 7 / span 6;
  }
`


const StyledTag = styled(props => <Tag {...props} />)`
  display: block;
  color: ${palette.teak};
  margin-top: 4em;
`


const mapDataToProps = ({ sanityPost }: any) => ({ ...sanityPost })


export const query = graphql`
  query project($slug: String!) {
    sanityPost(slug: {current: {eq: $slug}}) {
      slug {
        current
      }
      title
      description
      shareImage {
        asset {
          url
        }
      }
      _rawIntroduction
      tags {
        tag
      }
      inverted

      heroType
      heroHeight
      heroImageConditional {
        heroImage {
          asset {
            url
            metadata {
              dimensions {
                width
                height
                aspectRatio
              }
            }
          }
        }
        bleed
      }
      heroVideoConditional {
        heroVideo {
          asset {
            url
          }
        }
        bleed
      }

      heroParallaxConditional {
        gridItems {
          ... on SanityGridItem {
            ...gridItemFields
          }
        }
      }

      gridItems {
        ... on SanityGridItem {
          ...gridItemFields
        }
        ... on SanityGridItemStrip {
          ...gridItemStripFields
        }
        ... on SanityColorShifter {
          ...colorShifterFields
        }
        ... on SanityGridRule {
          _key
          _type
          ruleType
          columns
          columnStart
          rows
          sticky
          mobileColumns
          mobileColumnStart
          gapHeight
        }
      }
      gridSnap

    }

    sanityPage(slug: {current: {eq: "home"}}) {
      gridItems {
        ... on SanityGridItem {
          post {
            slug {
              current
            }
            title
            shareImage {
              asset {
                url
              }
            }
          }
        }
      }
    }

  }
`

export default WithData(mapDataToProps, CaseStudyTemplate)